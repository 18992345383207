import React from 'react';

// Layout components
import Layout from 'gatsby-theme-mate/src/components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Sections that will fetch data for you
import Landing from 'gatsby-theme-mate/src/sections/Landing';
import About from 'gatsby-theme-mate/src/sections/About';
// import Projects from 'gatsby-theme-mate/src/sections/Projects';
// import Writing from 'gatsby-theme-mate/src/sections/Writing';

const Home = () => (
  <Layout>
    <Header />
    <Landing />
    <About />
    <Footer />
  </Layout>
);

// const Home = () => (
//   <Layout>
//     <Header />
//     <Landing />
//     <Projects />
//     <About />
//     <Writing />
//     <Footer />
//   </Layout>
// );

export default Home;
